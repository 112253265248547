import React from "react";
import { Link } from "gatsby";
import PageLayout from "../components/pagelayout";
import SEO from "../components/seo";
import usePosts from "../hooks/useposts";
import Img from "gatsby-image";

const Articles = () => {
  const posts = usePosts();

  return (
    <PageLayout>
      <SEO title="Articles" />
      {posts.map(post => (
        <article key={post.slug} className="blog-post-wrapper">
          <Link to={post.slug}>
            <Img
              className="blog-image"
              fixed={post.image.sharp.fixed}
              title={post.title}
            />
          </Link>
          <div>
            <Link to={post.slug}>
              <h2 className="blog-title">{post.title}</h2>
            </Link>
            <p className="blog-excerpt">{post.excerpt}</p>
          </div>
        </article>
      ))}
    </PageLayout>
  );
};

export default Articles;
